const baseUrlProd = 'https://api-v1.autoit.dk';
const apiKey = "ce760c3b-2d44-4037-980b-894b79891525"

class VehicleSearchEndpoints {
    
    // Get Filteroptions
    async GetFilterOptions(filter)
    {
        let filterOptionsUrl = baseUrlProd + "/vehicle/filteroptions";
        let url = filterOptionsUrl + "?a=" + apiKey + "&filter=" + JSON.stringify(filter)

        var response = await fetch(url);

        if(!response.ok)
        {
            // Handle error
        }

        const filterOptions = await response.json();

        return filterOptions;
    }

    // Get all vehicles
    async GetVehicles(filter)
    {
        let vehiclesUrl = baseUrlProd + "/v2/vehicle";
        let url = vehiclesUrl + "?a=" + apiKey + "&filter=" + JSON.stringify(filter)

        var response = await fetch(url);

        if(!response.ok)
        {
            // Handle error
        }

        const vehicles = await response.json();

        return vehicles;
    }

    // Get OrderBy values
    async GetOrderByValues()
    {
        let orderByValuesUrl = baseUrlProd + "/vehicle/orderbyvalues";
        let url = orderByValuesUrl + "?a=" + apiKey

        var response = await fetch(url);

        if(!response.ok)
        {
            // Handle error
        }

        const orderByValues = await response.json();

        return orderByValues;
    }
}

export default new VehicleSearchEndpoints();